@import 'variables.module';
@import 'mixins';

.buttonPrimary {
  @include ButtonPrimary;
  color: $textColor;
}
.buttonOutlined {
  @include ButtonOutlined;
}
.buttonCancelcanada{
  color: $primary;
  border: 1px solid $primary;
}
.buttonViewcanada{
  color: $textColor;
}
.buttonSuccessOutlined {
  @include ButtonSuccessOutlined;
}
.buttonSuccessOutlined.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: $green;
}
.buttonCancel {
  @include ButtonPrimaryLow;
}
