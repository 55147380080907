@import '../../../assets/styles/variables.module.scss';
@import '../../../assets/styles/mixins.scss';

.topLine {
  position: sticky;
  top: 0;
  left: 0;
  height: 44px;
  width: 100%;
  background: $background;
  z-index: 100;
  box-shadow: 0px 1px 2px 0px #00000040;

  .activeItem {
    width: auto;
    padding-left: 50px;
    line-height: 44px;
    font-size: 14px;
    color: $primary;
  }
}

.line {
  position: absolute;
  height: 2px;
  width: 18px;
  background: #000;
  transition: all 0.3s ease;
}

.burger,
.close {
  position: absolute;
  top: 0;
  height: 44px;
  width: 44px;
  padding: 0;
  outline: none;
  cursor: pointer;
  z-index: 100;
}

.burger {
  left: 5px;

  &.disabled {
    pointer-events: none;

    .line {
      background: $light-grey;
    }
  }

  .line1 {
    left: 20%;
    top: 15px;
  }
  .line2 {
    left: 20%;
    top: 20px
  }
  .line3 {
    left: 20%;
    top: 25px;
  }
}

.close {
  right: 0;

  .line {
    left: 50%;
    top: 50%;
  }
  .line1 {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .line2 {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.navigationList {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  padding: 24px 0;
  background: $background;
  z-index: 100;

  .listItem {
    padding: 0 14px;
    line-height: 56px;
    font-size: 16px;
    color: $black;
    border-bottom: 1px solid $light-grey;
    cursor: pointer;
  }
}

@include respond(mobile) {
  .topLine {
    .activeItem {
      padding-left: 0;
      text-align: center;
    }
  }
}
