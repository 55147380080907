@import '../../../assets/styles/variables.module.scss';

:global {
  .ant-select-selector{
    color: $linkedText !important;
  }
  .filter-select.ant-select {
    margin: 0;
    width: auto !important;
    min-width: 100%;
    .ant-select-selector.ant-select-selector {
      border: 1px solid $dark-blue;
      border-radius: 6px;
    }

    .ant-select-selection-overflow {
      flex-wrap: nowrap;
    }
  }
}
