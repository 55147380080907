@import './variables';
@import './time-column';

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid $calendar-border;
  min-height: 0;

  .rbc-time-gutter {
    white-space: nowrap;
    text-align: right;
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-allday-events {
    position: relative;
    z-index: 4;
  }

  .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
  }

  .rbc-header {
    overflow: hidden;
    flex: 1;
    padding-top: 3px;
    color: $grey-1;
    height: 56px;
    display: flex;
    justify-content: center;

    .rbc-button-link {
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      text-transform: uppercase;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      padding: 7px;
    }

    &.rbc-today .rbc-button-link {
      background-color: $primary;
      color: $grey-1;
    }

    &.rbc-day-off-current-range .rbc-button-link {
      pointer-events: none;
    }
  }
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto; // should not shrink below height
  flex-direction: row;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid $cell-border;
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid $calendar-border;
  overflow-y: auto;
  position: relative;

  > .rbc-time-gutter {
    flex: none;
  }

  > * + * > * {
    border-left: 1px solid $cell-border;
  }

  > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
  }
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 2px;

  background-color: $current-time-color;
  pointer-events: none;
}
