@import '../../../assets/styles/globals';

.tabsContainer {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: $dark-blue;
  height: 72px;
  padding-left: 27px;
  padding-right: 27px;
  width: 100%;
  border-bottom: 1px solid rgba(229, 229, 229, 1);

  :global {
    .ant-tabs-nav {
      height: 71px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;

      &::before {
        border-bottom: 1px solid $dark-blue;
      }

      .ant-tabs-nav-wrap .ant-tabs-nav-list {
        .ant-tabs-tab {
          color: $light-grey;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: $grey-1;
        }
        .ant-tabs-tab.ant-tabs-tab-disabled {
          color: $grey-1;
        }

        .ant-tabs-ink-bar {
          height: 4px;
          background-color: $link;
        }
      }
    }
  }
}
