@import './globals';

:global {
  .container {
    .tableContainer {
      width: 100%;
    }

    .table-layout {
      border: 1px solid $grey-4;
    }

    .ant-modal .ant-modal-title {
      font-weight: 700;
      line-height: 25px;
    }

    .ant-modal .ant-modal-close {
      color: $black;
    }

    .ant-spin-container {
      border: 1px solid $grey-4;
      border-radius: 8px;
    }

    .ant-table-wrapper .ant-table-thead > tr > td,
    .ant-table-wrapper .ant-table-thead > tr > th {
      background-color: $grey-4;
      border: none;
      font-family: $body-font-family;
      font-weight: 600;
      font-size: 13px;
      line-height: 15.6px;
      color: $black;
    }

    .ant-table-wrapper .ant-table-thead > tr:first-child > th {
      padding: 16px 16px 0 16px;
    }

    .ant-table-wrapper .ant-table-thead > tr:not(tr:first-child) > th {
      padding: 5px 16px 16px 16px;
    }

    .ant-table-wrapper
      .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
        [colspan]
      )::before,
    .ant-table-wrapper
      .ant-table-thead
      > tr
      > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
        [colspan]
      )::before {
      content: none;
    }

    .ant-pagination .ant-pagination-item-active {
      border-color: $primary;
      background-color: $primary;
      a {
        color: $grey-1;
      }
    }

    .ant-table-wrapper .ant-table-tbody > tr > td {
      border-bottom: 1px solid $grey-5;
      a {
        color: $link;
        font-family: $body-font-family;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-decoration: underline;
      }
      p {
        color: $black;
        font-family: $body-font-family;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .ant-select {
      width: 65px;
      margin: 0 20px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      border-bottom: 1px solid $dark-blue;
      border-radius: 0px;
    }

    .ant-select .ant-select-selection-item,
    .ant-select .ant-select-arrow {
      color: $black;
    }

    .tableFooter {
      display: flex;
      padding-top: 16px;
      width: 100%;
      .tableSelect {
        flex-grow: 1;
      }
      .tablePagination {
        flex-grow: 2;
        .ant-pagination-options {
          display: none;
        }
      }
    }

    .ant-table-scroll-horizontal {
      .ant-table-cell-fix-left {
        left: 0 !important;
      }
    }
  }
}

.button {
  height: 30px;
  width: 104px;
  color: $textColor;
}

@include respond(tablet) {
  :global {
    .ant-table-content {
      overflow-x: auto;
    }
  }
}

@include respond(mobile) {
  :global {
    .tableFooter {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }

    .ant-table-cell-fix-left {
      width: 150px;
    }

    .tableSelect {
      margin-bottom: 10px;
    }

    .ant-pagination {
      .ant-pagination-item,
      .ant-pagination-prev {
        margin-inline-end: 0;
      }
    }
  }
}
