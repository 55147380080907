@import '../../../assets/styles/globals';

.wrap {
  padding-top: 60px;

  :global {
    .ant-modal .ant-modal-content {
      border-radius: 0;
      padding: 16px;

      .ant-modal-close {
        top: 16px;
        inset-inline-end: 16px;
        color: $black;
      }

      .ant-modal-body {
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}

.title {
  color: $black;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.6px;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.text {
  color: $black;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  margin: 0;
  margin-top: 14px;
}

.buttonPrimary {
  @include ButtonPrimary;
  font-weight: 600;
  border-radius: 2px;
  width: 100%;
  height: 50px;
  color: black;
}

.buttonPrimaryLow {
  @include ButtonPrimaryLow;
  width: 100%;
  height: 50px;
}
.buttonPrimaryLow:hover {
background-color: rgba(51, 88, 212, 1) !important;
  color: white !important;
}
.buttonsBlock {
  margin-top: 46px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 25px;
}

.icon {
  height: 38px;
}

@include respond(mobile) {
  .wrap {
    padding-top: 0;

    :global{
      .ant-modal {
        top: 45px;
      }
    }
  }
}
