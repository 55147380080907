@import '../../../../assets/styles/mixins.scss';

.actions {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  width: 100%;

  .button {
    min-width: 104px;
    height: 40px;
    padding: 8px auto;
    margin-left: 15px;
    border-radius: 0;
    color: $linkedText;
    background-color: $linkedOutlined;
    border: 1px solid $linkedOutlined;
  }
  .joinButton{
    color: rgba(0, 0, 0, 1);
    background-color: $primary;
    border: 1px solid $primary;
  }
  .confirmButton{
    background-color: $white;
    border: 1px solid rgba(6, 180, 23, 1);
    color: rgba(6, 180, 23, 1);
  }
}

@include respond(mobile) {
  .actions {
    flex-direction: column;

    .button {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
}
