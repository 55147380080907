@import '../../../assets/styles/globals.scss';

.container {
  border-radius: 10px;
  background-color: $dark-blue;
  padding: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  pointer-events: all;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header {
  color: $grey-1;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

.list {
  background-color: $background;
  flex: 1;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
}

.count {
  font-size: 12px;
  font-weight: 400;
}

.spinner {
  min-height: 200px;
  flex: 1;
  :global {
    .ant-spin-container {
      overflow: hidden;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.closeButton {
  position: absolute;
  right: 12px;
  top: 12px;
  color: $grey-1;
}

.footer {
  background-color: $background;
  border-top: 1px solid grey;
  padding: 16px;
  display: flex;
  align-items: flex-end;
}

.input {
  padding-top: 10px;
  padding-bottom: 10px;
}

.sendButton {
  width: 32px;
  height: 32px;
  padding: 0;
  margin-bottom: 5px;
  margin-left: 8px;
  color: $link;
}

.systemMessage {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  padding: 0 16px;
  margin-top: 4px;
  white-space: wrap;
  word-break: break-word;
  line-height: 18px;
  span {
    font-weight: 600;
  }
}

.partner {
  display: flex;
  padding: 0 16px;
  margin-top: 4px;
}

.partnerContent {
  flex: 1;
  flex-direction: column;
}

.partnerName {
  font-size: 12px;
  font-weight: 600;
  color: $grey-2;
  line-height: 15px;
  margin-bottom: 2px;
}

.partnerBubble {
  background-color: $gainsboro;
  border-top-right-radius: 16px;
}

.avatar {
  width: 36px;
}

.bubble {
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  line-height: 18px;
  font-size: 12px;
  padding: 7px 16px;
  white-space: pre-line;
}

.self {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
  margin-top: 4px;
}

.selfSpin {
  width: 26px;
  height: 26px;
  margin-right: 8px;
  padding-top: 4px;
}

.selfBubbleContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.pending {
  background-color: $light-blue !important;
}

.selfButton {
  background-color: $checkboxAviability;
  border-top-left-radius: 16px;
  border-top-right-radius: 0;
  flex: 1;
  height: auto;
  display: flex;
  text-align: left;
  color: $grey-1;

  &:disabled {
    background-color: $checkboxAviability;
    color: $grey-1;
    cursor: auto;
  }
}

.error {
  position: relative;
}

.errorIcon {
  color: $error;
  width: 13px;
  height: 13px;
  position: absolute;
  right: -9px;
  top: -6px;
}

.errorMessage {
  text-align: right;
  color: $error;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  padding-right: 6px;
}

.newThread {
  margin-top: 20px;
}

.affix {
  position: absolute;
  top: 0;
  right: 6px;
  width: 410px;
  bottom: 0;
  display: flex;
  pointer-events: none;
}

@include respond(mobile) {
  .affix {
    width: calc(100% - 12px);
    left: 6px;
  }
}
