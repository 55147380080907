@import '../../../assets/styles/globals';

:global {
  .ant-dropdown {
    .ant-list {
      padding: 0;
    }
  }
}

.container {
  max-width: 100%;
  margin-bottom: 40px;
  * {
    box-sizing: border-box;
  }
}

.topLine {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.filters {
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 1;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: flex-start;
}

.button {
  height: 40px;
  color: $textColor;
}

.iconButton {
  color: $icons;
  background-color: $background;
}

@include respond(tablet) {
  .container {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey-5;
  }
}

@include respond(mobile) {
  .container {
    margin-bottom: 0;
    padding-bottom: 15px;
  }
  .buttons {
    gap: 3px;

    :global {
      .ant-dropdown-trigger .ant-btn {
        padding-left: 0px;
      }
    }
  }
  .filters {
    :global {
      .ant-select {
        .ant-select-selector {
          padding-left: 0;
        }
      }
    }
  }
}
.link span {
  text-decoration: underline;
  color: $linkedText;
}
