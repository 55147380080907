.avatar_name_block{
    border-radius: 11px;
    background-color: rgba(0, 71, 241, 0.07);
    width: 77px;
    height: 54px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatar_name_text{
    color: rgba(0, 43, 183, 0.77);
    font-size: 18px;
}
.ai_hologram_container{
    position: absolute;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    top: 30%;
    right: 20px;
    gap: 10px;
}
.holobox_button{
    border: 0;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 72px;
    height: 100px;
    padding-top: 16px;
    padding-bottom: 8px;
    z-index: 20;
}
.holobox_button:disabled{
    opacity: 0.8;
    background: darkseagreen;
    cursor: not-allowed;
}
.holobox_button p{
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    font-weight: 510;
}
.live_call_icon{
    width: 34px;
    height: 34px;
}

.holobox_audio_button{
    background: rgba(201, 232, 202, 1);
    color: rgba(62, 155, 79, 1);
}
.holobox_audio_button:hover{
    color: rgba(251, 254, 251, 1);
    background: rgba(62, 155, 79, 1);
}
.holobox_start_live_call_button{
    background: rgba(210, 222, 255, 1);
    color: rgba(51, 88, 212, 1);
}
.holobox_start_live_call_button:hover{
    background: rgba(51, 88, 212, 1);
    color: rgba(251, 254, 251, 1);
}
.holobox_button:hover p{
    color: rgba(251, 254, 251, 1);
}
.holobox_logout_button{
    background: rgba(255, 205, 206, 1);
    color: rgba(220, 62, 66, 1);
}
.holobox_logout_button:hover{
    background: rgba(220, 62, 66, 1);
    color: rgba(255, 255, 255, 1);
}
.hologram_volume_container{
    display: flex;
    justify-content: center;
}
.holobox_volume_button{
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 20;
}
.holobox_muted_button{
    background: rgba(51, 88, 212, 1);
    color: rgba(253, 253, 254, 1);

}
.holobox_not_muted_button{
    color: rgba(51, 88, 212, 1);
}
body{
    margin: 0;
}
.loader_block{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%
}
.form{
    display: flex;
    gap: 12px;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
}
.video_block{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: white;
}
.video_block_content{
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
}
.chat-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
}

.chat_input {
    position: relative;
    bottom: 0;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 20px;
}
.messages_holobox_container{
    position: absolute;
    top: 23%;
    left: 25%;
    width: 50%;
    z-index: 30;
}
.messages_holobox{
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    margin: 0 auto;
    height: 300px;
}
.messages {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    width: 80%;
    margin: 0 auto;
    height: 300px;
}
.message{
    display: flex;
    padding: 5px 15px;
    border-radius: 30px;
    max-width: 70%;
}
.message p {
    margin: 0;
    font-weight: 590;
    font-size: 22px;
}
.message_left{
    align-self: flex-start;
    background-color: rgba(0, 0, 0, 0.45);
    color: white;
}
.message_right{
    align-self: flex-end;
    background-color: rgba(0, 0, 192, 0.64);
    color: white;

}
.error_message{
    align-self: center;
    background-color: red;
    color: white;
}
.idle_video_block{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 3;

}
.idle_image_block{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
}
.idle_image_block2{
    position: relative;
    z-index: 2 !important;
}
.ai_avatar_name{
    position: absolute;
    z-index: 3;
    top: 20px;
    left: 20px;
}
.timer_container{
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: rgba(0, 0, 51, 0.06);
    z-index: 5;
    width: 77px;
    height: 54px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.timer_text{
    font-size: 18px;
    color: rgba(0, 7, 20, 0.62);
}

@media (min-width: 1px) and (max-width: 1024px) {
    .ai_hologram_container{
        top: 40%;
    }
    .message p{
        font-size: 6px;
    }
    .holobox_button p{
        font-size: 6px;
        line-height: 8px;
    }
    .live_call_icon{
        width: 20px;
        height: 20px;
    }
    .holobox_button{
        border-radius: 10px;
        width: 36px;
        height: 70px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .holobox_volume_button{
        width: 16px;
        height: 16px;
    }
}
@media (min-width: 1080px) and (min-height: 1920px) {
    .message p{
        font-size: 22px;
    }
    .holobox_button p{
        font-size: 48px;
        line-height: 41px;
    }
    .live_call_icon{
        width: 128px;
        height: 128px;
    }
    .holobox_button{
        border-radius: 24px;
        width: 256px;
        height: 368px;
        padding-top: 64px;
        padding-bottom: 32px;
    }
    .holobox_volume_button{
        width: 192px;
        height: 192px;
    }
    .avatar_name_block{
        width: 206px;
        height: 105px;
        padding: 24px 48px;
        border-radius: 24px;
    }
    .avatar_name_text{
        font-size: 48px;
    }
    .timer_container{
        width: 206px;
        height: 105px;
        padding: 24px 48px;
        border-radius: 24px;
    }
    .timer_text{
        font-size: 48px;
    }
}