
.services{
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-content: center;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.header_with_logo_block{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: relative;
    z-index: 20;
}
.header_with_logo_img{
    margin: 1%;
    width: 100px;
}
.choose_services_for_live_call{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.services_components_block{
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}
.service_component{
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 6px;
    border: 0.3px solid rgba(153, 153, 153, 1);
    width: 30%;
    padding: 2%;
    justify-content: space-between;
}
.service_component_timer{
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 3px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
    color: rgba(0, 43, 183, 0.7725);
    font-size: 12px;
    background: rgba(0, 71, 241, 0.0706);
    width: 53px;
    height: 20px;
}
.service_component_title{
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 2%;
    line-height: 19px;
}
.service_component_brief{
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    color: rgba(153, 153, 153, 1);
    font-weight: 510;
}
.service_component_button{
    color: black;
    font-size: 14px;
    height: 30px;
}
.service_component_button:hover{
    background: rgba(53, 66, 97, 1) !important;
    color: rgba(255, 231, 112, 1) !important;
}
.service_component_linked_button{
    font-size: 12px;
    padding-left: 0;
    margin-bottom: 3%;
}
.contoledHeaderName{
    font-size: 30px;
    margin: 0;
}
.controledHeaderButton{
    flex-direction: row;
    width: 77px;
    height: 30px;
    align-items: center;
    padding: 0;
    padding: 8px;
    padding-right: 8px;
    border-radius: 3px;
}
.controledHeaderButtonText{
    color: rgba(220, 62, 66, 1) !important;
    font-size: 12px !important;
}
.controledHeaderButton:hover{
    color: white !important;
    background: rgba(220, 62, 66, 1) !important;
}
.controledHeaderButton:hover p{
    color: white !important;
}
.listOfUsersBlock{
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
}
.userCardBlock{
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 0.3px solid rgb(153, 153, 153);
    padding: 1%;
    width: 30%;
    justify-content: space-between;
}
.userCardBlockInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 1%;
}
.userCardBlockInfoTextBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
}
.userCardBlockInfoTextBlockName{
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin: 0;
}
.userCardBlockInfoTextBlockComment{
    font-size: 12px;
    line-height: 18px;
    max-width: 80%;
    color: rgba(153, 153, 153, 1);
    margin-top: 0;
}
.userCardBlockInfoButton{
    color: black;
    height: 30px;
    width: 100%;
    font-size: 14px;
    line-height: 1;
}
.userCardBlockInfoButton:hover{
    background: rgba(53, 66, 97, 1) !important;
    color: rgba(255, 231, 112, 1) !important;
}
.header_with_logo_img_holobox{
    display: none;
}
.header_with_logo_img_dekstop{
    display: block;
}
.controledHeaderIcon{
    margin-left: 10px;
    width: 16px;
    height: 16px;
}

@media (min-width: 1080px) and (min-height: 1920px) {
    .controledHeaderIcon{
        width: 48px;
        height: 48px;
    }
    .service_component_linked_button{
        margin-bottom: 10%;
        font-size: 36px;
    }
    .header_with_logo_img_holobox{
        display: block;
    }
    .header_with_logo_img_dekstop{
        display: none;
    }
    .header_with_logo_block{
        align-items: start;
    }
    .header_with_logo_img{
        margin: 3%;
        width: 320px;
    }
    .service_component{
        padding: 5%;

    }
    .service_component_title{
        font-size: 48px;
        margin-top: 10%;
        margin-bottom: 10%;
    }
    .service_component_timer{
        font-size: 36px;
        padding: 3%;
        width: 157px;
    }
    .service_component_brief{
        font-size: 32px;
    }
    .service_component_button{
        color: black;
        font-size: 32px;
        height: 128px;
    }
    .contoledHeaderName{
        font-size: 72px;
        line-height: 1;
    }
    .controledHeaderButton{
        width: 266px !important;
        height: 128px !important;
    }
    .controledHeaderButtonText{
        font-size: 36px !important;
    }
    .userCardBlockInfoTextBlockName{
        font-size: 48px;
    }
    .userCardBlockInfoTextBlockComment{
        font-size: 36px;
    }
    .userCardBlockInfoButton{
        height: 128px;
        font-size: 21px;
    }
}