@import '../../../../assets/styles/globals';

.statusProvider {
  color: rgba(161, 68, 175, 1);
}
.statusActive {
  color: rgba(62, 155, 79, 1);
}
.statusCanceled {
  color: $red-1;
}
.statusPatient {
  color: rgba(204, 78, 0, 1);
}
.statusPeernavigatorAppointment {
  color: $grey-7;
}
.statusCompleted {
  color: $grey-2;
}

.disabled {
  color: $grey-1;
}

.status {
  font-weight: 600;
}


@include respond(tablet) {
  .status {
    font-size: 13px;
  }
}
