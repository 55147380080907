.qRCodeScanBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.qRCodeScanBlockItem{
    display: flex;
    justify-content: center;
    border: 2px solid rgba(153, 153, 153, 1);
    width: 40%;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 32px;
    gap: 40px;
}

.qRCodeScanBlockItemText{
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
}
.qRCodeScanBlockItemList{
    color: rgba(153, 153, 153, 1);
    text-align: start;
    font-size: 12px;
    padding-left: 10px;
}
.qRCodeScanBlockCode{
    width: 150px;
    height: 150px;
}

@media (min-width: 1080px) and (min-height: 1920px) {
    .qRCodeScanBlockItemText{
        font-size: 64px;
    }
    .qRCodeScanBlockItem{
        width: 70%;
        align-items: center;
    }
    .qRCodeScanBlockCode{
        width: 400px;
        height: 400px;
    }

}

