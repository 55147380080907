@import '../../assets/styles/globals';

.button {
  @include ButtonPrimary;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: 600;
  border-radius: 2px;
  width: 217px;
  height: 40px;
  color: $textColor;
}
.aiAssistantButton{
  @include ButtonPrimary;
  position: absolute;
  width: 217px;
  height: 40px;
  top: 0;
  right: 250px;
  font-weight: 600;
  border-radius: 2px;
  color: $textColor;
}

.leftPanel,
.rightPanel {
  width: 30%;
}

.leftPanel {
  padding-right: 30px;
}

.content {
  position: relative;
  width: 70%;
}

.topLineProvider {
  display: flex;
  justify-content: flex-end;
}

.adminContainer {
  position: relative;

  .content {
    width: 100%;
  }

  .topLine {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}

.providerContainer {
  display: flex;
}

@include respond(tablet) {
  .content {
    width: 100%;
  }
  .adminContainer {
    display: flex;
    flex-wrap: wrap;

    .rightPanel {
      width: 100%;
    }
  }
}

@include respond(mobile) {
  .topLineProvider {
    position: fixed;
    bottom: 50px;
    left: 13px;
    right: 13px;
    z-index: 10;

    .button {
      width: 100%;
      color: $textColor;
    }
  }
}
