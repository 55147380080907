.notificationBar {
  display: flex;
  justify-content: center;
  background-color: white;
  width: 100%;
  color: black;
  text-align: center;
  line-height: 16px;
  font-size: 30px;
  padding-top: 10%;
  margin: auto;
}

.notificationError {
  position: relative;
  background: rgb(63, 65, 73);
  text-align: center;
  color: red;
}
