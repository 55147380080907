// $light-text: false;

$jamaica: (
        font: 'Poppins',
        primary: rgba(255, 231, 112, 1),
        secondary: rgba(51, 88, 212, 1),
        icons: rgba(18, 54, 90, 1),
        secondaryIcon: rgba(18, 54, 90, 1),
        darkBlue: rgba(255, 255, 255, 1),
        link: rgba(62, 99, 221, 1),
        error: #FF0000,
        warning: #BB6666,
        surveyColor: rgba(104, 200, 202, 0.5),
        gainsboro: #dddddd,
        onBackground: #222222,
        background: #FFFFFF,
        black: #000000,
        white: #FFFFFF,
        textColor: black,
        backgroundCancelButton: rgba(210, 222, 255, 1),
        checkboxAviability: #0066FF,
        linkedText: rgba(0, 71, 241, 1),
        linkedOutlined: rgba(0, 71, 241, 0.07)
);


body.jamaica {
  --font: #{map-get($jamaica, font)};
  --primary: #{map-get($jamaica, primary)};
  --secondary: #{map-get($jamaica, secondary)};
  --darkBlue: #{map-get($jamaica, darkBlue)};
  --link: #{map-get($jamaica, link)};
  --error: #{map-get($jamaica, error)};
  --warning: #{map-get($jamaica, warning)};
  --surveyColor: #{map-get($jamaica, surveyColor)};
  --gainsboro: #{map-get($jamaica, gainsboro)};
  --onBackground: #{map-get($jamaica, onBackground)};
  --background: #{map-get($jamaica, background)};
  --black: #{map-get($jamaica, black)};
  --white: #{map-get($jamaica, white)};
  --icons: #{map-get($jamaica, icons)};
  --secondaryIcon: #{map-get($jamaica, secondaryIcon)};
  --textColor: #{map-get($jamaica, textColor)};
  --backgroundCancelButton: #{map-get($jamaica, backgroundCancelButton)};
  --checkboxAviability: #{map-get($jamaica, checkboxAviability)};
  --linkedText: #{map-get($jamaica, linkedText)};
  --linkedOutlined: #{map-get($jamaica, linkedOutlined)};
}

$primary: var(--primary);
$secondary: var(--secondary);
$link: var(--link);
$dark-blue: var(--darkBlue);
$error: var(--error);
$warning: var(--warning);
$survey-color: var(--surveyColor);
$gainsboro: var(--gainsboro);
$onBackground: var(--onBackground);
$background: var(--background);
$black: var(--black);
$white: var(--white);
$icons: var(--icons);
$secondaryIcon: var(--secondaryIcon);
$textColor: var(--textColor);
$backgroundCancelButton: var(--backgroundCancelButton);
$checkboxAviability: var(--checkboxAviability);
$linkedText: var(--linkedText);
$linkedOutlined: var(--linkedOutlined);

$red-1: #e43333; // not found in app
$black-1: #161616; // not found in app
$light-grey: #a7a7a7; // light gray in jamaica not found in canada
$grey-1: #333333;// not found in app
$grey-2: #818a8f;//not found in app
$grey-3: #d9d9d9; // default ant border color
$grey-4: #f7f7f7;// not found in app
$grey-44: #f5f5f5;// not found in app
$grey-5: #eaeaea;// not found in app
$grey-7: #474646;// not found in app
$light-yellow: #FCF7EC;// not found in app
$light-pink: #edd8d8;// not found in app
$green: #06b417;// not found in app
$light-green: #c0ecc5;// not found in app
$light-blue: #9dc4eb; // pending in jamaica. Canada dont have
$border-disabled: rgba(0, 0, 0, 0.05);

$body-font-family: var(--font);

$common-action-width: 393px;

$mobile: 320px;
$tablet: 680px;
$desktop: 1200px;

:export {
  error: $error;
  darkBlue: $dark-blue;
  grey1: $grey-1;
  grey5: $grey-5;
  onBackground: $onBackground;
  primary: $primary;
  fontFamily: $body-font-family;
  background: $background;
  black: $black;
  mobile: $mobile;
  tablet: $tablet;
  desktop: $desktop;
  link: $link;
}
