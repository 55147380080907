@import '../../../../../assets/styles/globals.scss';

.iconButton {
  color: $icons;
  background-color: $background;
}

.badge {
  color: inherit;
  sup {
    width: 10px !important;
    height: 10px !important;
  }
}

.arrow {
  color: $link !important;
  font-size: 24px !important;
  pointer-events: none !important;
}

.list {
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
}

.content {
  :global {
    .ant-collapse-header {
      padding: 13px 14px !important;
    }

    .ant-collapse-content .ant-collapse-content-box {
      padding: 0;

      .ant-list-item {
        padding: 5px 12px;
        border-block-end: none;
      }
    }
  }
}

.select {
  :global {
    .ant-select-arrow {
      inset-inline-end: 4px;
    }
  }
}

.selectList {
  padding: 7px 0;

  :global {
    .ant-list-items .ant-list-item {
      padding: 5px 12px;
    }
  }
}

.popup {
  border-radius: 5px;
  padding: 0;
}

.search {
  border-bottom: 1px solid $link;

  :global {
    .ant-input-affix-wrapper:focus-within {
      box-shadow: none;
    }
  }
}

.searchIcon {
  color: $icons;
}

.searchList {
  :global {
    .ant-list-items .ant-list-item {
      padding: 5px 12px;
    }
  }
}
.link span {
  text-decoration: underline;
}
