@import '../../../assets/styles/globals';

.buttonContainer {
  display: flex;
  align-items: center;

}

.button {
  border: none;
  background-color: $background;

  &[disabled] {
    background-color: $background;
  }
}
