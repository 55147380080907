@import './variables';

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  border-radius: $event-border-radius;
  display: flex;
  align-items: center;
  // defines show more hidden items number and is relevant to calendar container height=610px
  height: 14px;
  padding-top: 2px;
  padding-bottom: 2px;

  span {
    font-size: 11px;
    padding-top: 1px;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
}

.rbc-event-type-icon {
  width: 16px;
  height: 16px;
  color: $icons;
}

.rbc-event-cell {
  @extend .rbc-ellipsis;
  @extend .rbc-event;
  height: 16px;
  padding-left: 2px;
  gap: 1px;
  cursor: pointer;
}

.rbc-event-dayoff {
  background-color: $day-off-bg;
  line-height: 16px;
  color: $grey-1;
  font-size: 11px;
  text-align: right;
  padding-right: 3px;
}

.rbc-event-active {
  color: $grey-1;
  background-color: $green;
}

.rbc-peer-navigator-event-active {
  border: 1px solid $green;
}

.rbc-event-confirmed {
  color: $black;
  background-color: $light-green;
}

.rbc-peer-navigator-event-confirmed {
  border: 1px solid $light-green;
}

.rbc-event-requested-by-patient  {
  color: $black;
  background-color: $light-pink;
}

.rbc-event-requested-by-provider  {
  color: $black;
  background-color: #EDD8A9;
}

.rbc-event-requested-by-tester  {
  color: $grey-1;
  background-color: lighten($grey-7, 35%);
}

.rbc-event-popup {
  @extend .rbc-event;
  height: 26px;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;

  span {
    text-decoration-line: underline;
    color: $dark-blue;
  }

  .light {
    color: $grey-1;
  }
}

.rbc-event-tooltip {
  max-width: 320px;
}
